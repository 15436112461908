export const unclaimedSewerApes = [
  22, 8484, 4072, 2094, 2326, 2447, 5052, 4093, 505, 3793, 3832, 1389, 3880,
  384, 699, 2678, 2712, 2365, 4084, 2907, 2697, 4474, 4731, 4563, 2642, 4332,
  2670, 4235, 680, 2709, 4885, 4102, 139, 4642, 961, 2414, 3908, 2805, 2702,
  2698, 2864, 5295, 1056, 5309, 4623, 2285, 2618, 2820, 4038, 3522, 4234, 1384,
  5285, 4688, 8112, 7017, 2714, 9759, 2160, 7533, 7274, 745, 2756, 5089, 7455,
  5531, 2759, 7835, 7065, 4490, 6357, 4466, 7950, 4800, 698, 5258, 1542, 7673,
  1355, 8266, 1779, 7896, 8182, 6464, 2967, 388, 4248, 5262, 8170, 1689, 7749,
  3369, 7403, 5266, 4931, 795, 205, 4857, 3525, 2026, 3966, 3460, 3921, 7921,
  4951, 677, 8164, 214, 4499, 5602, 6601, 6747, 483, 2568, 6410, 1679, 8070,
  7060, 6477, 3326, 8108, 4080, 3280, 224, 4798, 8386, 6908, 3830, 1644, 305,
  7302, 2608, 8462, 8846, 4167, 6593, 5995, 964, 9258, 8055, 1207, 7098, 3577,
  9087, 8357, 6082, 6883, 3927, 2701, 2332, 1016, 6463, 9604, 7558, 3691, 8459,
  9870, 3021, 8119, 9247, 5991, 9089, 700, 9959, 6210, 6921, 2560, 9039, 4852,
  4518, 6667, 6510, 7408, 4322, 4460, 8556, 9662, 9584, 5498, 5721, 9506, 5164,
  6226, 3057, 3398, 8430, 1621, 9132, 1570, 9302, 6793, 8637, 208, 2991, 5760,
  663, 6952, 620, 5312, 400, 8639, 3348, 1227, 527, 4607, 5325, 6364, 7019,
  5809, 1194, 9511, 2700, 2132, 7760, 7600, 5739, 1338, 9072, 9774, 6265, 596,
  3979, 5930, 188, 4569, 9668, 5208, 8434, 4533, 3884, 7212, 1365, 3345, 6154,
  1006, 7922, 5967, 5680, 8597, 6195, 6315, 2552, 6862, 5893, 9683, 9139, 230,
  1614, 678, 1514, 4894, 8389, 6989, 6701, 2675, 4216, 7732, 1364, 6978, 5851,
  9890, 6509, 5046, 6250, 5192, 2104, 6901, 932, 3475, 2249, 1536, 6642, 4672,
  8671, 5373, 7389, 823, 3209, 697, 5565, 5685, 9040, 6127, 8935, 7861, 681,
  8629, 6754, 4537, 9625, 4315, 4699, 6512, 2404, 7743, 5949, 8052, 9919, 819,
  5818, 7933, 6416, 636, 3670, 6627, 3856, 3567, 2740, 7347, 9330, 5202, 6695,
  4062, 9079, 3166, 2215, 8536, 9414, 9554, 5595, 5878, 5959, 628, 3245, 7939,
  192, 9260, 3498, 2565, 1804, 9198, 4393, 8175, 9925, 312, 1357, 5066, 6385,
  250, 957, 8874, 8678, 6877, 7454, 4625, 2983, 265, 6622, 6643, 9733, 7025,
  2842, 5736, 9917, 5627, 6242, 3432, 7957, 9058, 6888, 135, 7871, 9709, 1933,
  5668, 6270, 2711, 3581, 3349, 8860, 4754, 6301, 2476, 9480, 6503, 5847, 8080,
  4952, 7255, 9054, 8979, 1245, 7765, 7982, 4405, 5240, 7983, 3803, 7792, 9573,
  3467, 4988, 8427, 4863, 8085, 8045, 7980, 9586, 8385, 9519, 4387, 8301, 6615,
  4027, 2931, 3888, 6580, 6044, 7301, 5659, 5374, 6239, 7382, 6970, 1177, 7499,
  5679, 7231, 6291, 6192, 5656, 5371, 6569, 1602, 7571, 2446, 6411, 6129, 7636,
  6487, 5075, 5611, 5966, 7544, 6659, 3032, 4397, 6933, 48, 7329, 3907, 2491,
  3883, 3461, 4326, 8364, 8622, 9832, 1432, 2550, 4424, 7949, 3955, 9316, 8116,
  8409, 4733, 1918, 599, 4962, 1741, 7940, 4205, 7883, 9891, 1413, 6098, 5414,
  8470, 2695, 5297, 2416, 8325, 5183, 4172, 4116, 5722, 6043, 1309, 4122, 8919,
  5340, 4627, 8960, 196, 2822, 8673, 8946, 350, 5703, 9210, 2007, 8302, 8328,
  6808, 797, 4373, 1891, 4867, 8667, 2987, 7824, 8552, 5411, 2862, 736, 844,
  2233, 1506, 7418, 9975, 5123, 3761, 1394, 9951, 1759, 5936, 6778, 4215, 8442,
  2306, 9702, 675, 6943, 1788, 8621, 2131, 6172, 5338, 9883, 8011, 7371, 8111,
  4514, 674, 7931, 7446, 6900, 814, 1544, 7802, 3874, 7963, 1251, 6845, 2176,
  5490, 2829, 9587, 8405, 306, 8752, 8928, 7602, 2509, 8060, 3008, 712, 534,
  9267, 3499, 5598, 5797, 8570, 7882, 6789, 7536, 9742, 1766, 92, 5275, 3736,
  991, 9014, 2099, 2615, 6493, 8028, 5969, 3667, 2374, 3527, 1877, 5232, 4759,
  3269, 2713, 9969, 2271, 3933, 4362, 3469, 8446, 9153, 4955, 6884, 9479, 5126,
  9272, 7313, 2706, 9610, 3391, 168, 1469, 637, 9141, 6903, 5080, 2997, 2372,
  3320, 4061, 3994, 9325, 4303, 9112, 3257, 4629, 8057, 2521, 9308, 1735, 1067,
  946, 5465, 6107, 2514, 1527, 8406, 3688, 17, 6525, 2707, 6448, 9332, 9111,
  5512, 119, 5854, 4702, 6403, 9699, 1108, 7934, 6984, 2623, 5669, 629, 6872,
  3641, 1597, 7028, 7308, 9579, 8478, 480, 5383, 9994, 3976, 6820, 4266, 9211,
  4680, 7223, 7954, 7299, 2666, 7496, 8438, 1032, 6555, 9021, 7464, 1627, 766,
  4821, 4935, 9672, 2209, 8555, 9897, 6937, 5798, 8565, 7355, 7684, 8398, 3583,
  9069, 9274, 2828, 8870, 683, 5956, 8975, 5780, 9875, 8135, 2162, 4630, 8532,
  3431, 2068, 4776, 3934, 2079, 6450, 1387, 1773, 9790, 5372, 9186, 4877, 5225,
  6523, 8169, 8542, 1386, 4031, 7139, 7204, 6376, 1708, 2681, 8638, 3052, 3113,
  7056, 3078, 1098, 6971, 5947, 116, 4637, 8172, 37, 2659, 4052, 2259, 3061,
  6228, 5707, 9470, 611, 4781, 5287, 679, 587, 6141, 1105, 5812, 943, 1462,
  2471, 3347, 1436, 6485, 9658, 9567, 6234, 7045, 6048, 605, 5625, 6257, 2407,
  3807, 8075, 3929, 5444, 5682, 7276, 1294, 4079, 3204, 2194, 454, 9660, 6515,
  1185, 9049, 2705, 7548, 9706, 5532, 7186, 503, 8242, 7030, 3795, 6686, 7300,
  9140, 5963, 1989, 9055, 6473, 448, 6624, 8246, 4523, 198, 3190, 5138, 9996,
  1789, 9718, 5835, 9816, 1256, 3871, 7067, 1839, 4722, 8662, 9158, 8067, 1040,
  6351, 5500, 2080, 7580, 5422, 6031, 4465, 2703, 4684, 5241, 5846, 2821, 4156,
  8761, 9411, 3849, 7974, 4233, 8697, 8324, 826, 2951, 6783, 9569, 9792, 6446,
  2886, 2611, 5615, 2664, 1554, 9387, 1549, 3188, 8452, 2932, 4724, 7032, 7990,
  4583, 6294, 3658, 2708, 7834, 3850, 3164, 1478, 1733, 2650, 6931, 1581, 2504,
  4260, 6373, 1201, 3748, 7937, 3728, 6260, 2322, 1663, 2179, 4606, 8173, 1140,
  1449, 2277, 3796, 4418, 9353, 1610, 5519, 3400, 1031, 1799, 8287, 1884, 9485,
  8207, 6471, 1221, 7099, 7433, 9231, 4948, 8568, 6766, 4515, 9080, 2336, 2018,
  7180, 5061, 8906, 5127, 2242, 6923, 748, 930, 3568, 4921, 4531, 3319, 6050,
  5765, 3699, 7470, 9447, 7557, 8601, 9944, 5148, 9666, 3422, 9142, 9108, 5882,
  2622, 5218, 8731, 7401, 7425, 1141, 5475, 3001, 7108, 8669, 8338, 8634, 7594,
  3448, 8753, 1493, 1503, 9182, 3129, 8988, 1720, 5850, 5778, 2095, 5257, 2184,
  8366, 2696, 6102, 4793, 3133, 6397, 5832, 5147, 8539, 2765, 7660, 9164, 9452,
  5195, 5777, 7143, 3376, 9854, 5740, 9977, 8732, 9561, 8850, 7033, 9909, 9885,
  4158, 5675, 5440, 4897, 6869, 7376, 8770, 4862, 13, 8658, 5504, 8798, 4936,
];

export const unclaimedOthersideApes = [
  2660, 5126, 7850, 8924, 8671, 8207, 6291, 4969, 5765, 6228, 9139, 5625, 8045,
  5371, 5736, 8470, 7824, 8624, 5504, 8052, 8072, 7921, 8937, 8798, 8324, 4800,
  6098, 8878, 1010, 168, 678, 8846, 5675, 5422, 8112, 8080, 826, 6265, 1413,
  3190, 5149, 2756, 2695, 3422, 6778, 306, 2550, 5444, 2711, 2326, 9009, 698,
  677, 3527, 3139, 2131, 8442, 5258, 6766, 3348, 2706, 6835, 568, 7445, 3436,
  503, 6416, 8430, 7940, 6971, 3910, 8770, 736, 8608, 2285, 2276, 9777, 679,
  2708, 4936, 2257, 1018, 8389, 8085, 9554, 7743, 683, 1549, 6482, 7065, 1597,
  1506, 8565, 2696, 7600, 3581, 2864, 9579, 8764, 2709, 4672, 1031, 4637, 1462,
  6747, 5615, 2618, 2372, 1773, 9040, 9325, 2697, 4533, 4093, 2389, 6690, 9142,
  4332, 9017, 5123, 8860, 968, 5062, 2095, 675, 7286, 9807, 8622, 2345, 9332,
  4732, 2698, 3762, 5966, 2642, 7571, 681, 680, 1503, 2447, 6245, 2705, 2700,
  8542, 7180, 699, 6250, 534, 9601, 2277, 697, 1644, 5500, 198, 4078, 2199,
  6107, 6883, 4666, 2712, 7299, 2701, 1194, 2991, 8697, 9353, 5833, 5266, 230,
  4863, 9546, 6884, 7537, 4885, 5835, 9796, 4393, 4828, 5498, 7452, 4514, 5287,
  3884, 5797, 9207, 4505, 1884, 2713, 9854, 700, 2622, 4906, 1759, 2322, 3233,
  6102, 5809, 5325, 6473, 9164, 6908, 6862, 1338, 7302, 3568, 605, 3525, 3830,
  7239, 2604, 2504, 6924, 4684, 2080, 7309, 674, 9791, 2714, 3369, 2862, 9072,
  9014, 6820, 795, 2702, 2179, 9259, 2678, 3807, 4569, 9557, 2053, 7212, 2215,
  2681, 4499, 3432, 3793, 2615, 1124, 4097, 7580, 2707, 3522, 6817, 9959, 3667,
  3188, 9909, 4387, 4754, 3944, 7060, 2703, 2771, 4303, 4077, 6789, 9883, 7304,
  4274, 9095, 9480, 6877, 9247, 4531,
];

export const unclaimedApecoinApes = [
  826, 6291, 700, 8485, 2702, 675, 2701, 8860, 2524, 2708, 679, 697, 2179, 2372,
  699, 6250, 680, 6261, 2862, 2864, 2696, 674, 7824, 2326, 9164, 2714, 7060,
  8291, 168, 4672, 7563, 2712, 9332, 2709, 2703, 2504, 2615, 3369, 2700, 2698,
  2464, 2589, 8565, 1031, 2115, 8290, 5371, 2681, 8410, 6884, 4239, 8292, 2713,
  7580, 678, 677, 4684, 7281, 2695, 2705, 2711, 25, 4904, 683, 3884, 8622, 1469,
  3188, 2706, 698, 6747, 605, 681, 92, 8798, 3348, 6515, 8749, 1549, 2697, 2080,
  8846, 4387, 8288, 3568, 6820, 7445, 8207, 9247, 4514, 3190, 8289, 7180, 4885,
  2707, 8697,
];

const lostApes67 = [
  2862, 2707, 7180, 2703, 674, 2700, 4684, 2711, 2615, 7824, 6291, 3348, 2695,
  2372, 2179, 2080, 2326, 678, 2698, 3568, 8846, 8860, 9164, 4885, 9332, 3369,
  681, 697, 3884, 605, 8565, 2712, 699, 2681, 8798, 2714, 6747, 168, 2697, 4387,
  2709, 6820, 2713, 4514, 2708, 6250, 2701, 6884, 680, 677, 698, 3188, 2706,
  2705, 700, 2864, 3190, 8697, 4672, 7580, 679, 2504, 2696, 683, 5371, 2702,
  675,
];
